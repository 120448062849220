<template>
  <div>
    <Edit
      :item="currentItem"
      :update="updateItem"
      :close="closeEdit"
      :sistemas="sistemas"
      :descSingular="descSingular"
    />
    <Loading :isActive="loading"></Loading>
    <CTabs variant="tabs" class="nav-tabs-boxed">
      <CTab :title="descPlural">
        <div v-for="sistema in sistemas">
          <div class="table-responsive">
          <table class="table table-hover">
            <thead>
            <tr>
              <th colspan="4"><strong>{{ sistema.descricao }}</strong></th>
            </tr>
            </thead>
            <tbody>
            <tr v-for="item in sistema.modulos">
              <td class="highlight" style="width: 70px;">{{ item.id }}</td>
              <td>{{ item.descPlural }}</td>
              <td style="width: 100px;">
                <span class="label success" v-if="+item.ativo === 1">Ativo</span>
                <span class="label error" v-else>Inativo</span>
              </td>
              <td class="actions" style="width: 50px;">
                <button type="button" class="button button-primary" v-on:click="openEdit(item)"><fa-icon icon="pencil-alt" /></button>
              </td>
            </tr>
            <tr v-show="sistema.modulos.length === 0">
              <td colspan="4">
                Nenhum cadastro.
              </td>
            </tr>
            </tbody>
          </table>
          </div>
        </div>
      </CTab>
    </CTabs>
  </div>
</template>
<script>
import clone from 'just-clone';
import { put } from '../../helpers/apiRequest';
import Edit from './Edit';

export default {
  name: 'modulos',
  components: {
    Edit,
  },
  props: [
    'sistemas',
    'loadSistemas',
  ],
  data() {
    return {
      loading: false,
      currentItem: '',
      descPlural: '',
      descSingular: '',
    };
  },
  methods: {
    loadDescricaoModulo() {
      const { modulos } = JSON.parse(localStorage.getItem('app'));
      const { descPlural, descSingular } = modulos.find(
        (m) => m.nome === 'ADMIN_MODULO'
      );
      this.descPlural = descPlural;
      this.descSingular = descSingular;
    },
    openEdit(item) {
      this.currentItem = clone(item);
      this.$modal.show('moduloEdit');
    },
    closeEdit() {
      this.$modal.hide('moduloEdit');
    },
    updateItem() {
      const { id } = this.currentItem;
      this.loading = true;
      let request = put(`/admin/modulos/${id}`, this.currentItem);
      request.catch(() => (this.loading = false));
      request.then(() => {
        this.loading = false;
        this.loadSistemas();
      });
      return request;
    },
  },
  beforeMount() {
    this.loadDescricaoModulo();
  },
};
</script>
